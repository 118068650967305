<template>
    <div>

        <BPagination
                align="center"
                v-model="page"
                :total-rows="bankCardsPage.totalElements"
                :per-page="bankCardsPage.size"
                @change="changePage"
        />

        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col">Id</th>
                <th scope="col">Карта</th>
                <th scope="col">Паспорт</th>
                <th scope="col">Селфи</th>
                <th scope="col">Действие</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="card in allBankCards" v-bind:key="card.id">
                <td>{{ card.id }}</td>
                <td>
                    <div v-if="card.bankCard != null">
                        <p>Номер: {{ card.bankCard.number }}</p>
                        <p>Фото: <a :href="'https://storage.yandexcloud.net/mmnt-os-1/' + card.bankCard.photoUrl"
                                    target="_blank">{{
                            card.bankCard.photoUrl
                            }}</a></p>
                        <p>Комментарий: <input v-model="card.bankCard.comment" style="width: 200px"
                                               v-on:focus="clearInterval(updateRequestPolling)"/></p>
                        <span></span>
                        <p>Статус:
                            <b-dropdown :text="getStatus(card.bankCard.status)"
                                        v-on:click="clearInterval(updateRequestPolling)"
                                        :variant="getStatusVariant(card.bankCard.status)" size="sm">
                                <b-dropdown-item variant="warning" v-on:click="card.bankCard.status = 'UPLOADED'">
                                    Проверка
                                </b-dropdown-item>
                                <b-dropdown-item variant="success" v-on:click="card.bankCard.status = 'VERIFIED'">
                                    Одобрено
                                </b-dropdown-item>
                                <b-dropdown-item variant="danger" v-on:click="card.bankCard.status = 'ERROR'">Ошибка
                                </b-dropdown-item>
                            </b-dropdown>
                        </p>
                    </div>
                </td>
                <td>
                    <div v-if="card.passport != null">
                        <p>E-mail: {{ card.user.username }}</p>
                        <p>Фото: <a :href="'https://storage.yandexcloud.net/mmnt-os-1/' + card.passport.photoUrl"
                                    target="_blank">{{
                            card.passport.photoUrl
                            }}</a></p>
                        <p>Комментарий: <input v-model="card.passport.comment" style="width: 200px"
                                               v-on:focus="clearInterval(updateRequestPolling)"/></p>
                        <p>Статус:
                            <b-dropdown :text="getStatus(card.passport.status)"
                                        v-on:click="clearInterval(updateRequestPolling)"
                                        :variant="getStatusVariant(card.passport.status)" size="sm">
                                <b-dropdown-item variant="warning" v-on:click="card.passport.status = 'VERIFICATION'">
                                    Проверка
                                </b-dropdown-item>
                                <b-dropdown-item variant="success" v-on:click="card.passport.status = 'VERIFIED'">
                                    Одобрено
                                </b-dropdown-item>
                                <b-dropdown-item variant="danger" v-on:click="card.passport.status = 'ERROR'">Ошибка
                                </b-dropdown-item>
                            </b-dropdown>
                        </p>
                    </div>
                </td>
                <td>
                    <div v-if="card.selfie != null">
                        <p>E-mail: {{ card.user.username }}</p>
                        <p>Фото: <a :href="'https://storage.yandexcloud.net/mmnt-os-1/' + card.selfie.photoUrl"
                                    target="_blank">{{
                            card.selfie.photoUrl
                            }}</a></p>
                        <p>Комментарий: <input v-model="card.selfie.comment" style="width: 200px"
                                               v-on:focus="clearInterval(updateRequestPolling)"/></p>
                        <p>Статус:
                            <b-dropdown :text="getStatus(card.selfie.status)"
                                        v-on:click="clearInterval(updateRequestPolling)"
                                        :variant="getStatusVariant(card.selfie.status)" size="sm">
                                <b-dropdown-item variant="warning" v-on:click="card.selfie.status = 'VERIFICATION'">
                                    Проверка
                                </b-dropdown-item>
                                <b-dropdown-item variant="success" v-on:click="card.selfie.status = 'VERIFIED'">Одобрено
                                </b-dropdown-item>
                                <b-dropdown-item variant="danger" v-on:click="card.selfie.status = 'ERROR'">Ошибка
                                </b-dropdown-item>
                            </b-dropdown>
                        </p>
                    </div>

                </td>
                <td>
                    <button class="btn btn-success btn-sm" style="margin-right: 5px" type="button"
                            v-on:click="save(card)">Сохранить
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    name: 'BankCards',
    data: function () {
        return {
            updateRequestPolling: null,
            page: 1
        }
    },

    computed: {
        ...mapGetters(['allBankCards', 'bankCardsPage']),
    },

    methods: {
        ...mapActions(["fetchBankCards", "approveBankCard"]),
        save: async function (card) {
            //todo fix it
            if (card.user) card.user.authorities = null
            if (card.passport) card.passport.user.authorities = null
            if (card.selfie) card.selfie.user.authorities = null
            // card.bankCard.user.authorities = null

            if (
                (card.bankCard == null || card.bankCard.status == 'VERIFIED') &&
                (card.passport == null || card.passport.status == 'VERIFIED') &&
                (card.selfie == null || card.selfie.status == 'VERIFIED')
            ) {
                card.status = 'VERIFIED'
            } else {
                card.status = 'ERROR'
            }

            this.approveBankCard(
                card
            );
        },
        changePage: async function (newPage) {
            this.page = newPage;
            this.fetchBankCards(this.page);
        },
        clearInterval: function (interval) {
            clearInterval(interval);
        },
        startPolling: function () {
            this.updateRequestPolling = setInterval(() => {
                this.fetchBankCards(this.page);
            }, 5000);
        },
        getStatus: function (status) {

            switch (status) {
                case 'VERIFIED' :
                    return 'Одобрено';
                case 'VERIFICATION' :
                    return 'Проверка';
                case 'UPLOADED' :
                    return 'Проверка';
                case 'ERROR' :
                    return 'Ошибка';
            }
        },
        getStatusVariant: function (status) {
            switch (status) {
                case 'VERIFIED' :
                    return 'success';
                case 'VERIFICATION' :
                    return 'warning';
                case 'UPLOADED' :
                    return 'warning';
                case 'ERROR' :
                    return 'danger';
            }
        }
    },

    async mounted() {
        await this.fetchBankCards(this.page)
    },
    destroyed() {
        clearInterval(this.updateRequestPolling)
    }
}
</script>